var m = require("mithril")
var AppState = require("../services/AppState");
var Auth = require("../services/Auth");


class Schoollogistic {

    constructor() {
       this.status = null;
       this.list = [];
       this.count = 0;
       this.your_schoollogistic_count = "-";
       this.schoollogistic = null;
       this.date_filter = null;
       this.loadList = this.loadList.bind(this);
       this._receiveSchoollogisticData = this._receiveSchoollogisticData.bind(this);
    }

   _receiveSchoollogisticData(result) {
       this.list= result.results;
       this.count = result.count;
       // this.schoollogistic = result;

       this.your_schoollogistic_count = 0;
       this.list.forEach((schoollogistic) => {
                             // console.log(schoollogistic.id+" "+schoollogistic.crew+" "+Auth.crew_id);
                             if(schoollogistic.crew==Auth.crew_id){
                                this.your_schoollogistic_count++;
                             }
                         });


   }

   loadList() {
        if(this.date_filter == AppState.get_filter_date()) {
            // console.log("datumfilter geen verschil");
            return
        }
        let request_data = {
            method: "GET",
            url: Auth.getDomain()+"/api/schoollogistic/?date="+AppState.get_filter_date(),
        }
        Auth.authorize_request_data(request_data);
        this.date_filter = AppState.get_filter_date();
        return m.request(request_data)
                .then(this._receiveSchoollogisticData) // (result))
     }


}

module.exports = new Schoollogistic()
