var m = require("mithril")
var AppState = require("../services/AppState");
var Auth = require("../services/Auth");


class ChildEilandday {

    constructor() {
     // this.list =  [];
     this.status = "Stabiel";
     this.date_filter = null;
     this.childeiland_today_list = [];
     this.childeiland_daytype_today_list = [];
     this.childeiland_today_count = 0;

     this.loadOnEilandByDate = this.loadOnEilandByDate.bind(this);
     this.loadOnEilandByDateSuccess = this.loadOnEilandByDateSuccess.bind(this);

     this.my_childeiland_list = [];
     this.my_childeiland_count = 0;
     this.loadMyChildEilanddaysForChild = this.loadMyChildEilanddaysForChild.bind(this);
     this.receiveMyChildEilanddaysForChild = this.receiveMyChildEilanddaysForChild.bind(this);

     this.getChildEilanddaysForChild = this.getChildEilanddaysForChild.bind(this);
     this.updateChildEilandday = this.updateChildEilandday.bind(this);

     // ChildEilanddayPresence has OneOnOne relation with ChilEilandday
     //this.createChildEilanddayPresence = this.createChildEilanddayPresence.bind(this);
     //this.createChildEilanddayPresenceSucces = this.createChildEilanddayPresenceSucces.bind(this);
     //this.createChildEilanddayPresenceFail = this.createChildEilanddayPresenceFail.bind(this);

     this.addChildEilanddayPresence = this.addChildEilanddayPresence.bind(this);
     this.addChildEilanddayPresenceSucces = this.addChildEilanddayPresenceSucces.bind(this);
     this.addChildEilanddayPresenceFail = this.addChildEilanddayPresenceFail.bind(this);

     this.removeChildEilanddayPresence = this.removeChildEilanddayPresence.bind(this);
     this.removeChildEilanddayPresenceSucces = this.removeChildEilanddayPresenceSucces.bind(this);
     this.removeChildEilanddayPresenceFail = this.removeChildEilanddayPresenceFail.bind(this);


    }

    loadOnEilandByDateSuccess(result) {
        this.childeiland_today_count = result.count;
        this.childeiland_today_list = result.results;
        this.childeiland_today_list.forEach((childeilandday)=>{
                // dit gebruiken we om ook weer aanwezigheid af te melden
                if(childeilandday.presence_id) {
                    childeilandday.tapCount = 2;
                } else {
                    childeilandday.tapCount = 0;
                }
        })
        this.status = "Data Geladen";
        // _today_list is de 'parent'
        // nu uitpakken naar _daytype_today_list

        this.date_filter = AppState.get_filter_date();
    }

    loadOnEilandByDate() {
        if(this.date_filter == AppState.get_filter_date()) {
            return
        }
        this.childeiland_today_count = 0;
        this.childeiland_today_list = [];
        this.status = "Ophalen Voorbereiden";
        const request_data = {
            method: "GET",
            url: Auth.getDomain()+"/api/child-eilandday/"+AppState.get_filter_date()+"/"
            // todo: naar onderstaande format. API moet eerst.
            // url: Auth.domain+"/api/child-eilandday/?day="+AppState.get_filter_date()
            // url: Auth.domain+"/api/child-eilandday/?date="+AppState.get_filter_date()
        }
        Auth.authorize_request_data(request_data);
        this.status = "Ophalen Data";
        return m.request(request_data)
                .then(this.loadOnEilandByDateSuccess)
    }
    /**
     * create -> add
    createChildEilanddayPresence(childeilanddayId) {
        // console.log("createChildEilanddayPresence("+childeilanddayId+")");
        let formData = new FormData();
        formData.append('childeilandday', childeilanddayId);
        formData.append('crew', '1'); // wordt hergevuld op de server. id moet wel bestaan
        const request_data = {
            method: "POST",
            url: Auth.getDomain()+"/api/childeilanddaypresent/",
            body: formData,
            extract: (xhr) => {return {status: xhr.status, body: JSON.parse(xhr.responseText)}}
        }
        Auth.authorize_request_data(request_data);
        m.request(request_data)
         .then(this.createChildEilanddayPresenceSucces)
         .catch(this.createChildEilanddayPresenceFail);
         // .catch((e) => { console.error("ChildEilanddayPresenceCreate Error: "+e);
         //                })
    }
    createChildEilanddayPresenceSucces(result) {
        // console.log("createChildEilanddayPresenceSucces result: "+result.body );
        // Als status == 201 (created)
        //   Loop over  this.childeiland_today_list
        //     Als: childeilandday.id gevonden:
        //     Dan: zet is_present locaal op op True
        if(result.status==201) {
            this.childeiland_today_list.forEach((childeilandday)=>{
                if(childeilandday.id == result.body.childeilandday) {
                    childeilandday.is_present = true;
                    childeilandday.presence_id = result.id;
                }
            })
        }
    }
    createChildEilanddayPresenceFail(result) {
        alert("Presentie aanmaken is mislukt: ("+result+")");
    }
    */

    getChildEilanddaysForChild(childId) {
        let child_childeiland_list = [];
        this.childeiland_today_list.forEach((childeilandday)=>{
            if(childeilandday.child == childId) {
                child_childeiland_list.push(childeilandday);
            }
        })
        // console.warn("// reverse zet de tijden toevallig goed, maar dat blijft een gokje. implementeer sort(),
        // of doe het helemaal niet");
        return child_childeiland_list //.reverse()
    }

    addChildEilanddayPresence(childEilanddayId) {
        //console.log("addChildEilanddayPresence("+childEilanddayId+")");
        const request_data = {
            method: "PUT",
            url: Auth.getDomain()+"/api/childeilandday/"+childEilanddayId+"/add-presence/" //,
            // extract: (xhr) => {return {status: xhr.status, body: JSON.parse(xhr.responseText)}}
        }
        Auth.authorize_request_data(request_data);
        m.request(request_data)
         .then(this.addChildEilanddayPresenceSucces)
         .catch(this.addChildEilanddayPresenceFail);
    }
    addChildEilanddayPresenceSucces(result) {
        // console.log("addChildEilanddayPresenceSucces: "+result);
        this.childeiland_today_list.forEach((childeilandday)=>{
            if(childeilandday.id == result.id) {
                childeilandday.is_present = true;
                childeilandday.presence_id = result.presece_id;
                childeilandday.tabCount= 2
            }
        })

        // ontvangen wordt de nieuwe ChileEilandDay. Zet die in de lijst
        // result.tabCount= 2;
        // console.log(">"+result.id+" "+result.is_present+" "+result.presence_id+"<");
        // this.updateChildEilandday(result);  Dit krijg ik niet goed
    }
    addChildEilanddayPresenceFail(result) {
        alert("Presentie Toevoegen is mislukt ("+result+").");
    }


    removeChildEilanddayPresence(childEilanddayId) {
        //console.log("removeChildEilanddayPresence("+childEilanddayId+")");
        const request_data = {
            method: "DELETE",
            //url: Auth.getDomain()+"/api/childeilanddaypresent/"+childEilanddayId+"/" //,
            url: Auth.getDomain()+"/api/childeilandday/"+childEilanddayId+"/remove-presence/" //,
            // extract: (xhr) => {return {status: xhr.status, body: JSON.parse(xhr.responseText)}}
        }
        Auth.authorize_request_data(request_data);
        m.request(request_data)
         .then(this.removeChildEilanddayPresenceSucces)
         .catch(this.removeChildEilanddayPresenceFail);
    }
    removeChildEilanddayPresenceSucces(result) {
        // console.log("removeChildEilanddayPresenceSucces: "+result);
        this.childeiland_today_list.forEach((childeilandday)=>{
            if(childeilandday.id == result.id) {
                childeilandday.is_present = false;
                childeilandday.presence_id = null;
                childeilandday.tabCount= 0
            }
        })

        // ontvangen wordt de nieuwe ChileEilandDay. Zet die in de lijst
        // result.tabCount= 0;
        // result.is_present = false;  // dit is gek als het moet
        // result.presence_id = null;  // en dit ook
        // console.log(">"+result.id+" "+result.is_present+" "+result.presence_id+"<");
        // this.updateChildEilandday(result);  Dit krijg ik niet goed
    }
    removeChildEilanddayPresenceFail(result) {
        alert("Presentie Verwijderen is mislukt ("+result+").");
    }

    // Dit lijkt niet goed te werken. Waarom is mij niet duidelijk.
    updateChildEilandday(new_childeilandday) {
        // console.log("UpdateCeD: "+new_childeilandday);
        for (const _index in this.childeiland_today_list) {
            // console.log(new_childeilandday.id+" ?== "+this.childeiland_today_list[_index].id);
            if (new_childeilandday.id == this.childeiland_today_list[_index].id) {
                // console.log(new_childeilandday.id+" Duw nieuwe erin:"+new_childeilandday.is_present+" "+new_childeilandday.presence_id);
                // this.childeiland_today_list[_index] = null; dit beklijft wel.
                this.childeiland_today_list[_index] = new_childeilandday; // dit niet
                return
            }
        }
        throw "KindElanddag met id "+new_childeilandday.id+" niet gevonden.";
    }

    receiveMyChildEilanddaysForChild(result) {
        this.my_childeiland_count = result.count
        this.my_childeiland_list = result.results
        this.status = "Data Geladen";
    }

    // Voor Cateaker om rooster van kind op te halen
    loadMyChildEilanddaysForChild(childId) {
        this.childeiland_today_count = 0;
        this.childeiland_today_list = [];
        this.status = "Ophalen Voorbereiden";
        var request_data = {
            method: "GET",
            url: Auth.getDomain()+"/api/my-child/"+childId+"/schedule/"
            // todo: naar onderstaande format. API moet eerst.
            // url: Auth.domain+"/api/child-eilandday/?day="+AppState.get_filter_date()
            // url: Auth.domain+"/api/child-eilandday/?date="+AppState.get_filter_date()
        }
        Auth.authorize_request_data(request_data);
        this.status = "Ophalen Data";
        return m.request(request_data)
                .then(this.receiveMyChildEilanddaysForChild)
    }


}


module.exports = new ChildEilandday()

