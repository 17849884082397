var m = require("mithril")
var AppState = require("../services/AppState")
var Auth = require("../services/Auth");
var ChildEilandday = require("../models/ChildEilandday")


class ChildPresence {

    constructor(vnode) {
        // this._childeilandday = vnode.attrs.childeilandday;
        this.updateIsPresent = this.updateIsPresent.bind(this);
        this.updateIsNotPresent = this.updateIsNotPresent.bind(this);
    }

    updateIsPresent(event) {
       event.preventDefault();
       //console.debug("updateIsPresent");
       this.tapCount++;
       if(this.tapCount==3) {
           // de value heeft de childeilandday_id
           ChildEilandday.createChildEilanddayPresence(event.target.attributes.value);
       }
    }

    updateIsNotPresent(event) {
       event.preventDefault();
       //console.debug("updateIsNotPresent");
       this.tapCount--;
       if(this.tapCount==0) {
           // de value heeft de presence_id
           ChildEilandday.removeChildEilanddayPresence(event.target.attributes.value);
       }
    }

	view(vnode) {
        this._childeilandday = vnode.attrs.childeilandday;
        this.tapCount = this._childeilandday.tapCount; // steeds de orginele waarde uit het Model
	    // Alleen Tonen Als Groep filter aanstaat, en het kind aanwezigheidsplicht heeft
        if (AppState.daytypeFilter > 0 &&
            this._childeilandday?.child_full.on_eiland_school ) {
           // Als present al staat, dan moet het ook weer afgenoem n kunenn wordem
           if(this._childeilandday.is_present ){
               if(this.tapCount == 3) {
                   return m('.pull-left.list-item-action.present_check.present_green',
                            {style: "font-size: smaller;",
                             value: _childeilandday.present_id,
                             onclick: this.updateIsNotPresent},
                             'Aanwezig')
               }
               return m('.pull-left.list-item-action.present_check.not_present_'+this.tapCount, // geel
                         {style: "font-size: smaller;",
                          value: _childeilandday.id,
                          onclick: this.updateIsMotPresent},
                         'Aanwezig ('+this.tapCount+')')

           }
           // Als nog niet Present, dan tappen voor aanmelden
           if(!this._childeilandday.is_present){
               if(this.tapCount == 0){
                   return m('.pull-left.list-item-action.present_check.not_present_'+this.tapCount, // geel
                             {style: "font-size: smaller;",
                              value: _childeilandday.id,
                              onclick: this.updateIsPresent},
                             'Niet Aanwezig')
               }
               return m('.pull-left.list-item-action.present_check.not_present_'+this.tapCount, // geel
                         {style: "font-size: smaller;",
                          value: _childeilandday.id,
                          onclick: this.updateIsPresent},
                         'Niet Aanwezig ('+this.tapCount+')')

           }
        }
        // niet tonen
        return null
    }
}


class ChildPresenceWrapper {
    // De wrapper zit om 'het blok' dat aangeeft of een kind er is of niet.
    // Zolang een kind niet als 'aanwezig' is bestempeld, worden de 'taps' erop geteld.
    // Bij 2 tqps wordt kind op 'aanwezig' gezet
    constructor(vnode) {
        this._childeilandday = vnode.attrs.childeilandday;
        this.tapCount = this._childeilandday.tapCount; // steeds de orginele waarde uit het Model
        //console.log(">>"+this._childeilandday.id+" "+this._childeilandday.is_present+" "+
        //            this._childeilandday.tapCount+ " "+this._childeilandday.presence_id+"<<");
        this.maxTapCount = 2; // voor wat meer controle over per ongeluk klikken
        this.updateIsPresent = this.updateIsPresent.bind(this);
        this.updateIsNotPresent = this.updateIsNotPresent.bind(this);

        this.view = this.view.bind(this);

        // this.childeilandday_id = vnode.attrs.childeilandday.id;
    }

    updateIsPresent(event) {
       event.preventDefault();
       if(this.tapCount<this.maxTapCount) {
         this.tapCount++;
       }
       //console.debug("updateIsPresent: "+this.tapCount+" "+event.target.attributes);
       if (this.tapCount==this.maxTapCount) {
           ChildEilandday.addChildEilanddayPresence(this._childeilandday.id);
           //event.target.attributes.value.value);
       }
    }

    updateIsNotPresent(event) {
       event.preventDefault();
       if(this.tapCount>0){
          this.tapCount--;
       }
       //console.debug("updateIsNotPresent: "+this.tapCount +" "+event.target.attributes);
       if (this.tapCount==0) {
           ChildEilandday.removeChildEilanddayPresence(this._childeilandday.id);
           // de value heeft de presence_id
           // event.target.attributes.value.value);
       }
    }

	view(vnode) {
        //this._childeilandday = vnode.attrs.childeilandday;
        //this.tapCount = this._childeilandday.tapCount; // steeds de orginele waarde uit het Model
	    // Alleen Tonen Als Groep filter aanstaat, en het kind aanwezigheidsplicht heeft
        if (AppState.daytypeFilter > 0 &&
            this._childeilandday?.child_full.on_eiland_school ) {
             // Als presentie al staat, dan moet het ook weer afgenomen kunnen worden
             //console.log(this._childeilandday.id+" "+this._childeilandday.is_present);
             if(this._childeilandday.is_present ){
               if(this.tapCount == this.maxTapCount) {
                   return m('.pull-left.list-item-action',
                            {style: "font-size: smaller;",
                             'data-presence_id': this._childeilandday.presence_id,
                             'data-ced_id': this._childeilandday.id,
                             onclick: this.updateIsNotPresent},
                            [vnode.attrs.content,
                             m('.present',
                               {style: "font-size: smaller;"},
                               'Aanwezig')
                               ])
               }
               return m('.pull-left.list-item-action',
                         {style: "font-size: smaller;",
                          'data-presence_id': this._childeilandday.presence_id,
                          'data-ced_id': this._childeilandday.id,
                          onclick: this.updateIsNotPresent},
                         [vnode.attrs.content,
                          m('.present_yellow',
                            {style: "font-size: smaller;"},
                            'Aanwezig ('+this.tapCount+')')
                            ])

             } else {
               // Als nog niet Present, dan tappen voor aanmelden
               if(this.tapCount == 0){
                   return m('.pull-left.list-item-action',
                             {style: "font-size: smaller;",
                              'data-ced_id': this._childeilandday.id,
                              onclick: this.updateIsPresent},
                              [vnode.attrs.content,
                               m('.not_present',
                                 {style: "font-size: smaller;"},
                                 'Niet Aanwezig')
                                 ])
               }
               return m('.pull-left.list-item-action',
                         {style: "font-size: smaller;",
                          'data-ced_id': this._childeilandday.id,
                          onclick: this.updateIsPresent},
                         [vnode.attrs.content,
                          m('.present_yellow',
                            {style: "font-size: smaller;"},
                            'Niet Aanwezig ('+this.tapCount+')')
                            ])

             }
        }
        // niet tonen
        throw "Hier mogen we dan niet komen?"
    }
/*
        if(_childeilandday.is_present) {
           return m('.pull-left.list-item-action',
                    [vnode.attrs.content,
                     m('.present_green', {style: "font-size: smaller;"},
                       'Aanwezig')])
        }
        let tapCountMsg = "";
        if (this.tapCount > 0) {
          tapCountMsg = " ("+this.tapCount+")";
        }
        return m('.pull-left.list-item-action',
                 {style: "font-size: smaller;",
                  value: _childeilandday.id,
                  onclick: this.updateIsPresent},
                 [vnode.attrs.content,
                  m('.not_present_'+this.tapCount, {style: "font-size: smaller;"}, 'Niet Aanwezig'+tapCountMsg)])

        }
*/
}


class ChildImg {
   constructor(vnode) {
   }

   view(vnode) {
     const _child = vnode.attrs.child
     let class_media_obj_size = ".small";
     return ( m('.child-img',
                m('img.media-object.pull-left'+class_media_obj_size, { src: _child.mugshot_url }))
             )
   }
}


class ChildName {
   constructor(vnode) {
       // this.child = vnode.attrs.child
   }

   view(vnode) {
     const _child = vnode.attrs.child
     return ( m('.pull-left.full-name',
                [ m('.first-name-big', _child.first_name),
                  m('.last-name', _child.last_name) ] ))
   }
}


class ChildImgName {
   constructor(vnode) {
   }

   view(vnode) {
     const _child = vnode.attrs.child
     const _with_image = vnode.attrs.withImg ? vnode.attrs.withImg : false;
     const _childeilandday = vnode.attrs.childeilandday ? vnode.attrs.childeilandday : null;

     const class_media_obj_size = ".small";
     let mugshot_url = _child.mugshot_url;
     if( mugshot_url.startsWith("//www.gravatar.com/")) {
        mugshot_url = "https:"+mugshot_url;
     }
     if (!mugshot_url.startsWith("http")) {
        mugshot_url = Auth.getDomain()+mugshot_url;
     }
     let _content = m('.child-img-name.pull-left',
                      [ m('.w2c-1.pull-left',
                        [m('img.media-object.pull-left'+class_media_obj_size, { src: mugshot_url }),
                         m(ChildName, {child: _child,})]),
                      ])
     // console.log("Alleen Wrappen als Groep filter aanstaat, en het kind aanwezigheidsplicht
     //    heeft: "+AppState.daytypeFilter+" "+_childeilandday?.child_full.on_eiland_school);
     if (AppState.daytypeFilter > 0 &&
          _childeilandday?.child_full.on_eiland_school ) {
         return m(ChildPresenceWrapper,
                     { childeilandday: _childeilandday,
                      content: _content})
     }
     return _content
   }
}


module.exports = ChildImgName
