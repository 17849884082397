var m = require("mithril")
var Auth = require("../services/Auth")
var AppState = require("../services/AppState")

// Of is het meer een Service??
// Servie aggregeerd meer over verschillende modellen.

class Settings {

    constructor() {
        this.list =  [];
        this.settingsSubList =  [];
        this.changed = false;
        // this.count = 0;

        this._receiveSettingsList = this._receiveSettingsList.bind(this);
        this.loadList = this.loadList.bind(this);
        this.loadList()
        this.store = this.store.bind(this);
        this.getSetting = this.getSetting.bind(this);
        this.getValue = this.getValue.bind(this);
        this.setValue = this.setValue.bind(this);
        this.setValueEvent = this.setValueEvent.bind(this);
        this.setSettingsSubList = this.setSettingsSubList.bind(this);
        this.getSettingsSubList = this.getSettingsSubList.bind(this);
    }

    _receiveSettingsList(result) {
        this.count = result.count;
        this.list =  result.results;
        console.debug("this.list: "+this.list);
    }

    loadList() {
        const request_data = {
            method: "GET",
            url: Auth.getDomain()+"/api/settings/",
        }
        Auth.authorize_request_data(request_data);
        return m.request(request_data)
                .then(this._receiveSettingsList)
                .catch((e) => {
                        //console.error("loadList Error: "+e);
                        const dummyResult = { count: 3, next: null,prev: null, // krijgen we erbij
                                             results: [ {name: "child_order", value: 0}
                                                       ,{name: "daytype_filter", value: 0}
                                                       ,{name: "menu_order", value: 0}]
                                            }
                        // console.debug("loadList dummy: "+dummyResult);
                        this._receiveSettingsList(dummyResult);
                       })
    }

    // Save the Settings on the server
    store() {
        //console.info("Settings Bewaren: "+JSON.stringify(this.list));
        localStorage.setItem("evgSettings", JSON.stringify(this.list))
        this.changed = false; // Het is Bewaard
    }

    getSetting(name) {
         //console.debug("this.list: "+this.list+" "+this.list.length);
         try {
             for (let i = 0; i < this.list.length-1; i++) {
                  //console.log("i: "+i);
                  //console.log("this.list[i]: "+this.list[i]);
                  if( this.list[i].name == name) {
                     return this.list[i]
                  }
             }
             //console.info("Setting '"+name+"' Niet Gevonden: ");
         } catch (e) {
            console.error("Fout Vinden Setting '"+name+"': "+e);
         }
         return null
    }

    getValue(name) {
         try {
             let _setting = this.getSetting(name);
             if(_setting) {
                 return _setting.value
             }
             return value
         } catch (e) {
            console.error("Value Voor Setting '"+name+"' Niet Gevonden: "+e);
            // console.debug("Opties in this.list: "+this.list);
         }
         return null
    }

    setValue(name, value) {
         //console.log("this.list.length: "+this.list.length);
         //console.log("setValue: "+name+" "+value);
         try {
             for (let i = 0; i < this.list.length-1; i++) {
                  // console.log("setValue: "+ i +" "+name+" "+value);
                  // console.log("setValue: "+ i +" "+this.list[i].name+" "+this.list[i].value);
                  if( this.list[i].name == name) {
                     this.list[i].value = value;
                     this.changed = true; // Het moet nog bewaard
                     return
                  }
             }
             // console.info("Setting '"+name+"' Niet Gevonden. Toevoegen aan "+JSON.stringify(this.list));
             this.list.push({name, value});
             this.changed = true; // Het moet nog bewaard
             // console.info("Setting '"+name+"' Niet Gevonden. Toegevoegd "+JSON.stringify(this.list));
             return
         } catch (e) {
            console.error("Fout Vinden Setting '"+name+"': "+e);
         }
         // this.changed = true; // Het moet nog bewaard
    }

    setValueEvent(event) {
         //console.log("setValueEvent: "+event.target.name+" "+event.target.value);
         this.setValue(event.target.name, event.target.value);
    }

    getAllSettings() {
        //console.log("getAllSettings: "+ JSON.stringify(this.list));
        return this.list
    }

    // For modules to indicate they have a rewuirement for a subset of settings
    setSettingsSubList(subList) {
       this.settingsSubList = subList;
    }
    getSettingsSubList(subList) {
        return this.settingsSubList
    }
}

module.exports = new Settings()
